<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="London Contact Information"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
              >
                <QuillEditor
                  :editorRef="'footerContact1Editor'"
                  v-model="addCourseForm.footer_contact1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Dubai Contact Information"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Dubai"
              >
                <QuillEditor
                  :editorRef="'footerContact2Editor'"
                  v-model="addCourseForm.footer_contact2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Kuala Lumpur Contact Information"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Kuala"
              >
                <QuillEditor
                  :editorRef="'footerContact3Editor'"
                  v-model="addCourseForm.footer_contact3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Barcelona Contact Information"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Barcelona"
              >
                <QuillEditor
                  :editorRef="'footerContact4Editor'"
                  v-model="addCourseForm.footer_contact4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Select City"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="City"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.footer_cities"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="citiesList"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Select Category"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="City"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.footer_categories"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  multiple
                  :options="coursesList"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Whatsapp link"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
              >
                <b-form-input v-model="addCourseForm.footer_whatsapp" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Facebook link"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
              >
                <b-form-input v-model="addCourseForm.footer_facebook" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> </b-col><b-col md="4">
            <b-form-group
              label="Twitter link"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
              >
                <b-form-input v-model="addCourseForm.footer_twitter" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Linked-in link"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
              >
                <b-form-input v-model="addCourseForm.footer_linkedin" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group
              label="Youtube link"
              label-for="blog-edit-title"
              class="mb-2">
              <validation-provider #default="{ errors }" name="Title">
                <b-form-input v-model="addCourseForm.footer_youtube" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import QuillEditor from '@core/components/editor/Editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    const citiesList = ref([])

    store.dispatch('cities/cititesList').then(response => {
      citiesList.value = response.data
    })
    const form = ref({
      footer_contact1: '',
      footer_contact2: '',
      footer_contact3: '',
      footer_contact4: '',
      footer_cities: '',
      footer_categories: '',
      footer_whatsapp: '',
      footer_facebook: '',
      footer_linkedin: '',
      footer_twitter: '',
    })
    const addCourseForm = reactive(form)
    const data = [
      'footer_contact1',
      'footer_contact4',
      'footer_contact2',
      'footer_contact3',
      'footer_cities',
      'footer_categories',
      'footer_whatsapp',
      'footer_facebook',
      'footer_linkedin',
      'footer_twitter',
    ]
    const formData = new FormData()
    formData.append('data', JSON.stringify(data))
    store.dispatch('setting/GetSettingArray', formData).then(response => {
      response.data.forEach(el => {
        if (el.name == 'footer_categories') {
          form.value.footer_categories = el.value
        }
        if (el.name == 'footer_twitter') {
          form.value.footer_twitter = el.value
        }
        if (el.name == 'footer_facebook') {
          form.value.footer_facebook = el.value
        }
        if (el.name == 'footer_whatsapp') {
          form.value.footer_whatsapp = el.value
        }
        if (el.name == 'footer_linkedin') {
          form.value.footer_linkedin = el.value
        }
        if (el.name == 'footer_contact1') {
          form.value.footer_contact1 = el.value
        }
        if (el.name == 'footer_contact2') {
          form.value.footer_contact2 = el.value
        }
        if (el.name == 'footer_contact3') {
          form.value.footer_contact3 = el.value
        }
        if (el.name == 'footer_contact4') {
          form.value.footer_contact4 = el.value
        }
      })
    })

    const coursesList = ref([])

    store.dispatch('categories/AllCategory').then(response => {
      coursesList.value = response.data.data
    })

    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      const data = [
        {
          name: 'footer_contact1',
          value: addCourseForm.value.footer_contact1,
        },
        {
          name: 'footer_contact2',
          value: addCourseForm.value.footer_contact2,
        },
        {
          name: 'footer_contact3',
          value: addCourseForm.value.footer_contact3,
        },
        {
          name: 'footer_contact4',
          value: addCourseForm.value.footer_contact4,
        },
        {
          name: 'footer_cities',
          value: addCourseForm.value.footer_cities,
        },
        {
          name: 'footer_categories',
          value: addCourseForm.value.footer_categories,
        },
        {
          name: 'footer_contact4',
          value: addCourseForm.value.footer_contact4,
        },
        {
          name: 'footer_whatsapp',
          value: addCourseForm.value.footer_whatsapp,
        },
        {
          name: 'footer_facebook',
          value: addCourseForm.value.footer_facebook,
        },
        {
          name: 'footer_linkedin',
          value: addCourseForm.value.footer_linkedin,
        },
        {
          name: 'footer_twitter',
          value: addCourseForm.value.footer_twitter,
        },
      ]

      formData.append('data', JSON.stringify(data))

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          store
            .dispatch('setting/updateSettingArray', formData)
            .then(response => {
              Vue.swal({
                title: 'Updated',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr
      let formData
      const token = localStorage.getItem('token')

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', 'https://api.lmitac.com/api/upload_image_tiny_mce')

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }
    return {
      example_image_upload_handler,
      addCourseForm,
      form,
      addCourseFormvalidate,
      coursesList,
      citiesList,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    QuillEditor,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
  .col-12 {
    margin: 0px !important;
  }
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
