var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"London Contact Information","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('QuillEditor',{attrs:{"editorRef":'footerContact1Editor'},model:{value:(_vm.addCourseForm.footer_contact1),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "footer_contact1", $$v)},expression:"addCourseForm.footer_contact1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Dubai Contact Information","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Dubai"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('QuillEditor',{attrs:{"editorRef":'footerContact2Editor'},model:{value:(_vm.addCourseForm.footer_contact2),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "footer_contact2", $$v)},expression:"addCourseForm.footer_contact2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Kuala Lumpur Contact Information","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Kuala"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('QuillEditor',{attrs:{"editorRef":'footerContact3Editor'},model:{value:(_vm.addCourseForm.footer_contact3),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "footer_contact3", $$v)},expression:"addCourseForm.footer_contact3"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Barcelona Contact Information","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Barcelona"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('QuillEditor',{attrs:{"editorRef":'footerContact4Editor'},model:{value:(_vm.addCourseForm.footer_contact4),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "footer_contact4", $$v)},expression:"addCourseForm.footer_contact4"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select City","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"City","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.citiesList,"reduce":function (val) { return val.id; }},model:{value:(_vm.addCourseForm.footer_cities),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "footer_cities", $$v)},expression:"addCourseForm.footer_cities"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select Category","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"City","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"type","multiple":"","options":_vm.coursesList,"reduce":function (val) { return val.id; }},model:{value:(_vm.addCourseForm.footer_categories),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "footer_categories", $$v)},expression:"addCourseForm.footer_categories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Whatsapp link","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addCourseForm.footer_whatsapp),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "footer_whatsapp", $$v)},expression:"addCourseForm.footer_whatsapp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Facebook link","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addCourseForm.footer_facebook),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "footer_facebook", $$v)},expression:"addCourseForm.footer_facebook"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Twitter link","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addCourseForm.footer_twitter),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "footer_twitter", $$v)},expression:"addCourseForm.footer_twitter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Linked-in link","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addCourseForm.footer_linkedin),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "footer_linkedin", $$v)},expression:"addCourseForm.footer_linkedin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }